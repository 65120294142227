import React from "react"
import styled from "styled-components"
import { Random } from "random-js"
import Hashrune from "./Hashrune"

interface Props {}

interface State {
  genesisNames: Record<number, string>
  firstEditionNames: Record<number, string>
}

export default class Browse extends React.Component<Props, State> {
  state: State = { genesisNames: {}, firstEditionNames: {} }
  random = new Random()

  async componentDidMount() {
    const genesisData = await fetch("https://api.hashrunes.com/v1/runes-name/0")
    const genesisNames = await genesisData.json()
    const firstEditionData = await fetch(
      "https://api.hashrunes.com/v1/runes-name/1",
    )
    const firstEditionNames = await firstEditionData.json()
    this.setState({ genesisNames, firstEditionNames })
  }

  render() {
    const { firstEditionNames, genesisNames } = this.state
    const genesisTokenIds = Object.keys(genesisNames)
    const firstEditionTokenIds = Object.keys(firstEditionNames)
    return (
      <DivContainer>
        <h2>Genesis (Random 100)</h2>
        <div className="Browse--hashrunes">
          {this.random
            .sample(genesisTokenIds, Math.min(genesisTokenIds.length, 100))
            .map(i => {
              const tokenId = parseInt(i) + 1
              return (
                <Hashrune
                  key={tokenId}
                  className="Browse--hashrune"
                  edition={0}
                  isLink
                  name={genesisNames[tokenId]}
                  tokenId={tokenId}
                  useApi
                />
              )
            })}
        </div>
        <h2>First Edition (Random 100)</h2>
        <div className="Browse--hashrunes">
          {this.random
            .sample(
              firstEditionTokenIds,
              Math.min(firstEditionTokenIds.length, 100),
            )
            .map(i => {
              const tokenId = parseInt(i) + 1
              return (
                <Hashrune
                  key={tokenId}
                  className="Browse--hashrune"
                  edition={1}
                  isLink
                  name={firstEditionNames[tokenId]}
                  tokenId={tokenId}
                  useApi
                />
              )
            })}
        </div>
      </DivContainer>
    )
  }
}

const DivContainer = styled.div`
  .Browse--hashrunes {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

    .Browse--hashrune {
      margin: 4px;
      width: auto;
    }
  }

  @media (min-width: 900px) {
    .Browse--hashrunes {
      grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
    }
  }
`
